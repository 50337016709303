import { crudActions } from "@/services/CrudActions";
import apiClient from "@/services/ApiClient";

export default {
  ...crudActions("clientes"),
  searchItems(search, mode) {
    // mode: “contains” or “startsWith”
    return apiClient.post(`/clientes/search`, { search: search, mode: mode });
  },
  getSepaPdf(id) {
    return apiClient.get(`/clientes/${id}/sepa`);
  },
  getClientByCoordinates(latitude, longitude) {
    return apiClient.get(
      `/clientes/search-by-coordinates?latitude=${latitude}&longitude=${longitude}`
    );
  },
  listDoors(id) {
    return apiClient.get(`/clientes/${id}/products`);
  },
};
