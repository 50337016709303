import { crudActions } from "@/services/CrudActions";
import apiClient from "@/services/ApiClient";

export default {
  ...crudActions("partes"),
  getAgentsList(id = undefined) {
    let path = "/tecnicos/list";
    if (id) path += `?parte_id=${id}`;
    return apiClient.get(path);
  },
  getArticles(id = undefined) {
    let path = `/article-families/list`;
    if (id) path += `?parte_id=${id}`;
    return apiClient.get(path);
  },
  generarAlbaran(id) {
    return apiClient.post(`/partes/generar-albaran/${id}`);
  },
};
