import { crudActions } from "@/services/CrudActions";
import apiClient from "@/services/ApiClient";

export default {
  ...crudActions("products"),
  createQuote(id) {
    return apiClient.post(`/products/${id}/crear-presupuesto`);
  },
  getCustomer(id) {
    return apiClient.get(`/products/${id}/info-cliente`);
  },
};
